import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import {
  useQuery,
  gql
} from "@apollo/client";
import {InlineShareButtons} from 'sharethis-reactjs';
import PageHeader from '../../../components/page-header.js';
import Breadcrumbs from '../../../components/breadcrumbs.js';
import Grid from '@material-ui/core/Grid';
import CDPTopContentBlocks from './cdpTopContentBlock.js';
import CDPBottomContentBlocks from './cdpBottomContentBlock.js';
import StoryCard from '../../../components/storyCard.js';
import NewFooter from '../../../../pages-other/NewFooter.js';
import './creativityDesignPlay.css';

const QUERY = gql`
    query NewQuery {
        entry(slug: "creativity-design-and-play") {
            ... on pages_default_Entry {
              title
              slug
              id
              blurb
              contentOptions {
                ... on contentOptions_standardContent_BlockType {
                  contentTitle
                  contentContent
                  linkUrl {
                    url
                    customText
                    target
                  }
                  contentAlignment
                }
                ... on contentOptions_icp_BlockType {
                  icpTitle
                  icpContent
                    icpImage {
                        url
                    }
                    icpImageCaption
                    icpPositionOfImage
                    icpBackgroundColor
                }
                ... on contentOptions_fwi_BlockType {
                  fwiTitle
                  fwiImage {
                      url
                  }
                  fwiCaption
                  fwiSize
                }
                ... on contentOptions_emphasized_BlockType {
                  emphasizedTitle
                  emphasizedSubheadline
                  emphasizedOverline
                  emphasizedImage {
                    url
                  }
                  emphasizedImageCaption
                  emphasizedContent
                  emphasizedLink
                  emphasizedBackgroundColor
                }
                ... on contentOptions_quote_BlockType {
                      pullQuote
                      quoteSocial
                      quoteImage {
                        url
                      }
                      quoteAttribution
                }
                ... on contentOptions_quoteSlideshow_BlockType {
                  quoteSliderTitle
                  quotes {
                    __typename  
                    ... on quotes_BlockType {
                      quotesQuote
                      quotesQuoteImage {
                        url
                      }
                      quotesQuoteAttribution
                    }
                  }
                }
                ... on contentOptions_slider_BlockType {
                  sliderTitle
                  slides {
                    __typename
                    ... on slides_BlockType {
                      slideImage {
                        url
                      }
                      slideHeadline
                      slideContent
                      slideLink {
                        customText
                        url
                        target
                      }
                    }
                  }
                }
                ... on contentOptions_list_BlockType {
                  listTitle
                  list {
                    __typename  
                    ... on list_BlockType {
                      listItemHeadline
                      listItemContent
                      listItemRichContent
                      listItemImage {
                        url
                      }
                      listItemLink {
                        url
                        customText
                        target
                      }
                    }
                  }
                }    
                ... on contentOptions_accordion_BlockType {
                  accordionTitle
                  accordion {
                    __typename  
                    ... on accordion_BlockType {
                      accordionItemTitle
                      accordionItemBlurb
                      accordionItemImage {
                        url
                      }
                      accordionItemIcon {
                        url
                      }
                      accordionItemContent
                    }
                  }
                }
                ... on contentOptions_linkList_BlockType {
                  linkListTitle
                  linkListList {
                    __typename  
                    ... on linkListList_BlockType {
                      linkListTitle
                      linkUrl
                      linkListBg {
                        url
                      }
                      linkListHoverTxt
                      linkListTitle
                    }
                  }
                  linkListDisplay
                  linkListSize
                  columnCount
                }
                ... on contentOptions_video_BlockType {
                  videoTitle
                  videoLink
                  videoCaption
                  videoImage {
                    url
                  }
                }
                ... on contentOptions_featuredResource_BlockType {
                  featuredResource {
                    title 
                    ... on resources_default_Entry {
                      blurb
                      researchAsset {
                        url
                      }
                      interestAreaTeam {
                        ... on interestAreaTeam_BlockType {
                          interestAreaTeamMember {
                            title
                            url
                          }
                        }
                      }
                      researchExternalAuthors {
                        ... on researchExternalAuthors_BlockType {
                          researchExternalAuthorsAuthor
                        }
                      }
                      featuredImageCaption
                    }
                  }
                }
                ... on contentOptions_featuredPerson_BlockType {
                  featuredPerson {
                    title 
                    url
                    ... on teamMembers_default_Entry {
                      teamMembersPronouns
                      teamMemberRoles {
                        title
                      }
                      teamMemberImage {
                        url
                      }
                      teamMemberPosition
                      teamMemberBio
                    }
                  }
                }
                ... on contentOptions_featuredStory_BlockType {
                  featuredstory {
                    title 
                    url
                    author {
                      fullName
                    }
                    dateCreated @formatDateTime (format: "M j, Y")
                    ... on stories_default_Entry {
                      blurb
                      featuredImage {
                        title
                        url
                      }
                      categories {
                        title
                      }
                      themes {
                        title
                      }
                      interestAreas {
                        title
                      }
                      researchExternalAuthors {
                        ... on researchExternalAuthors_BlockType {
                          researchExternalAuthorsAuthor
                        }
                      }
                      interestAreaTeam {
                        ... on interestAreaTeam_BlockType {
                          interestAreaTeamMember {
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        }
  }
`

const cdpStoriesQuery = gql`
    query researchQuery {
      entries(section: "stories", limit: 3, relatedToCategories: [{ id: 29216 }]) {
        title
        slug
        url
        author {
          fullName
        }
        postDate @formatDateTime (format: "M j, Y")
        ... on stories_default_Entry {
          researchExternalAuthors {
            ... on researchExternalAuthors_BlockType {
              researchExternalAuthorsAuthor
            }
          }
          interestAreaTeam {
            ... on interestAreaTeam_BlockType {
              interestAreaTeamMember {
                title
              }
            }
          }
          blurb
          featuredImage {
            url
          }  
          categories {
            title
          }
        }
      }
    }
`

const RecentStoriesForCDPCards = () => {
  const { data, loading, error } = useQuery(cdpStoriesQuery);
  if (loading) return null;
  if (error) return `Error! ${error}`;

// console.log('Recent Stories for Teens Card Data', data);

return (
  data.entries.map(
    (block) => {
      
      let props = {
        block: {block}
      }
      return (
        <Grid item xs={12} sm={4}>
          <StoryCard {...props} />
        </Grid>
      )
    }
  )
)
}

const RecentStoriesForCDPBlock = () => {

  return (
    <section className='cdpCraftStories'>
        <div id="cdp-craftStories-container">
        <div className="cdp-craftStories-content-container">
          <h2 className="cdp-craftStories-title"><span>Latest Stories</span></h2>
        <section className='cdpCraftHighlightCards'>
            <section className='cdpCraftStories'>
              <div className="container">
                <Grid container spacing={4}>
                  <RecentStoriesForCDPCards />
                </Grid>
              </div>
           </section>
        </section>   
        <div className="cdp-craftStories-bottomLink">
              <a href='/stories?cat=29216' className="cdp-craftStories-content-bottomLink">Explore more stories<i className="fa-solid fa-arrow-right-long"></i></a>
            </div>
        </div>
        </div>
    </section>
  )
}

const CDPTopInlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }

    const shareThisStyle = {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Helvetica Neue Medium",
    fontSize: "18px"
  };

  return (
    <Fragment>
      <CDPTopContentBlocks data={data.entry} />
      <div className="share" style={{ display: 'flex', alignItems: 'center' }}>
        <a className="text-half-step_bold st-custom-button" data-network="sharethis" style={shareThisStyle}>
          <InlineShareButtons
              config={{
                  alignment: 'center',  // alignment of buttons (left, center, right)
                  color: 'social',      // set the color of buttons (social, white)
                  // enabled: true,        // show/hide buttons (true, false)
                  font_size: 20,        // font size for the buttons
                  labels: 'cta',        // button labels (cta, counts, null)
                  language: 'en',       // which language to use (see LANGUAGES)
                  networks: [           // which networks to include (see SHARING NETWORKS)
                      'whatsapp',
                      'linkedin',
                      'messenger',
                      'facebook',
                      'twitter'
                  ],
                  padding: 12,          // padding within buttons (INTEGER)
                  radius: 4,            // the corner radius on each button (INTEGER)
                  show_total: true,
                  size: 40,             // the size of each button (INTEGER)
                  // OPTIONAL PARAMETERS
              }}
            >
          </InlineShareButtons>
        </a>
      </div>
    </Fragment>
  );
}

const BottomInlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }

  return (
    <CDPBottomContentBlocks data={data.entry} />
  );
}


export default class CDPCraft extends Component {
  render() {
    return(
        <section>
            <Helmet>
              <title>foundry10 - Career-Connected Learning and Life Skills</title>
              <meta
                name="title"
                content="foundry10 - Career-Connected Learning and Life Skills"
              />
              <meta
                name="description"
                content="Discover how to help young people prepare to thrive in their careers and adult lives. Explore resources from our research, education programs, and philanthropy."
              />
              <meta property="og:title" content="foundry10 - Career-Connected Learning and Life Skills" />
              <meta
                property="og:description"
                content="Discover how to help young people prepare to thrive in their careers and adult lives. Explore resources from our research, education programs, and philanthropy."
              />
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://www.foundry10.org/interest-areas/career-connected-learning-and-life-skills" />
              <link rel="canonical" href="https://www.foundry10.org/interest-areas/career-connected-learning-and-life-skills" />
              {/* Structured Data Script Tag */}
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org",
                    "@type": "AboutPage",
                    "name": "foundry10 - Career-Connected Learning and Life Skills",
                    "description": "Discover how to help young people prepare to thrive in their careers and adult lives. Explore resources from our research, education programs, and philanthropy.",
                    "url": "https://www.foundry10.org/interest-areas/career-connected-learning-and-life-skills"
                  }
            `   }
              </script>
            </Helmet>
            <PageHeader slug={this.props.slug} />
            <Breadcrumbs slug={this.props.slug} />
            <CDPTopInlineContent slug='creativity-design-and-play' />
            <RecentStoriesForCDPBlock />
            <BottomInlineContent slug='creativity-design-and-play' />
            <NewFooter />
        </section>
      
    );
  }
}
